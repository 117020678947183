// ECMA-262, Edition 5, 15.4.4.18
// Référence: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {

    Array.prototype.forEach = function (callback, thisArg) {

        var T, k;

        if (this == null) {
            throw new TypeError(' this vaut null ou n est pas défini');
        }

        // 1. Soit O le résultat de l'appel à ToObject
        //    auquel on a passé |this| en argument.
        var O = Object(this);

        // 2. Soit lenValue le résultat de l'appel de la méthode
        //    interne Get sur O avec l'argument "length".
        // 3. Soit len la valeur ToUint32(lenValue).
        var len = O.length >>> 0;

        // 4. Si IsCallable(callback) est false, on lève une TypeError.
        // Voir : http://es5.github.com/#x9.11
        if (typeof callback !== "function") {
            throw new TypeError(callback + ' n est pas une fonction');
        }

        // 5. Si thisArg a été fourni, soit T ce thisArg ;
        //    sinon soit T égal à undefined.
        if (arguments.length > 1) {
            T = thisArg;
        }

        // 6. Soit k égal à 0
        k = 0;

        // 7. On répète tant que k < len
        while (k < len) {

            var kValue;

            // a. Soit Pk égal ToString(k).
            //   (implicite pour l'opérande gauche de in)
            // b. Soit kPresent le résultat de l'appel de la
            //    méthode interne HasProperty de O avec l'argument Pk.
            //    Cette étape peut être combinée avec c
            // c. Si kPresent vaut true, alors
            if (k in O) {

                // i. Soit kValue le résultat de l'appel de la
                //    méthode interne Get de O avec l'argument Pk.
                kValue = O[k];

                // ii. On appelle la méthode interne Call de callback
                //     avec T comme valeur this et la liste des arguments
                //     qui contient kValue, k, et O.
                callback.call(T, kValue, k, O);
            }
            // d. On augmente k de 1.
            k++;
        }
        // 8. on renvoie undefined
    };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });
}

if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target === null || target === undefined) {
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource !== null && nextSource !== undefined) {
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

// Modification d'un comportement par défaut du JavaScript Navigateur
// Pour boucler sur des éléments DOM comme un $(elem).each(...
// Magic line!

NodeList.prototype.forEach = Array.prototype.forEach;
HTMLCollection.prototype.forEach = Array.prototype.forEach;

if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function () {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}


if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {
            }
            return i > -1;
        };
}

//polyfill closest
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
    Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };


var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
} : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var is = {
    array: function array() {
    },

    window: function window(thing) {
        return thing === win.window || isWindow(thing);
    },

    docFrag: function docFrag(thing) {
        return is.object(thing) && thing.nodeType === 11;
    },

    object: function object(thing) {
        return !!thing && (typeof thing === 'undefined' ? 'undefined' : _typeof(thing)) === 'object';
    },

    function: function _function(thing) {
        return typeof thing === 'function';
    },

    number: function number(thing) {
        return typeof thing === 'number';
    },

    bool: function bool(thing) {
        return typeof thing === 'boolean';
    },

    string: function string(thing) {
        return typeof thing === 'string';
    },

    element: function element(thing) {
        if (!thing || (typeof thing === 'undefined' ? 'undefined' : _typeof(thing)) !== 'object') {
            return false;
        }

        var _window = getWindow(thing) || win.window;

        return (/object|function/.test(_typeof(_window.Element)) ? thing instanceof _window.Element //DOM2
                : thing.nodeType === 1 && typeof thing.nodeName === 'string'
        );
    }
};

var isIOS7 = /iP(hone|od|ad)/.test(navigator.platform) && /OS 7[^\d]/.test(navigator.appVersion);

var rectUtils = {
    getStringOptionResult: function getStringOptionResult(value, interactable, element) {
        if (!is.string(value)) {
            return null;
        }

        if (value === 'parent') {
            value = parentNode(element);
        } else if (value === 'self') {
            value = interactable.getRect(element);
        } else {
            value = closest(element, value);
        }

        return value;
    },

    resolveRectLike: function resolveRectLike(value, interactable, element, functionArgs) {
        value = rectUtils.getStringOptionResult(value, interactable, element) || value;

        if (is.function(value)) {
            value = value.apply(null, functionArgs);
        }

        if (is.element(value)) {
            value = getElementRect(value);
        }

        return value;
    },

    rectToXY: function rectToXY(rect) {
        return rect && {
            x: 'x' in rect ? rect.x : rect.left,
            y: 'y' in rect ? rect.y : rect.top
        };
    },

    xywhToTlbr: function xywhToTlbr(rect) {
        if (rect && !('left' in rect && 'top' in rect)) {
            rect = extend({}, rect);

            rect.left = rect.x || 0;
            rect.top = rect.y || 0;
            rect.right = rect.right || rect.left + rect.width;
            rect.bottom = rect.bottom || rect.top + rect.height;
        }

        return rect;
    },

    tlbrToXywh: function tlbrToXywh(rect) {
        if (rect && !('x' in rect && 'y' in rect)) {
            rect = extend({}, rect);

            rect.x = rect.left || 0;
            rect.top = rect.top || 0;
            rect.width = rect.width || rect.right - rect.x;
            rect.height = rect.height || rect.bottom - rect.y;
        }

        return rect;
    }
};


function HTMLForForeach(objs) {
    if (
        isNode(objs) || isWindow(objs)
    ) {
        if (objs.length <= 1 || isWindow(objs)) {
            objs = [objs];
        }
    }

    if (isElement(objs)) {
        objs = [objs];
    }
    if (objs == null) {
        return false;
    }
    if (typeof objs && typeof objs.length === "undefined") {
        objs = [objs];
    }

    return objs;
}

// On attend que le contenu de la page web soit entièrement chargé avant d'executer CmonSite Editor
// (à voir si c'est vraiment utile étant donné qu'on charge ce script en bas de page)
function wait(condFunc, readyFunc, checkInterval) {
    var checkFunc = function () {
        if (condFunc()) {
            readyFunc();
        } else {
            setTimeout(checkFunc, checkInterval);
        }
    };
    checkFunc();
}


/**
 * Ajouter un ou plusieurs evenement
 * @param {type} obj
 * @param {type} evts
 * @param {type} handler
 * @returns {undefined}
 */
function on(objs, events, handler, options = false) {

    if (objs === null) {
        return false;
    }

    objs = HTMLForForeach(objs);
    objs.forEach(function (obj) {
        var t, evt, evts = events;
        // on verifie si il y a plusieurs élements
        evts = (evts || "").match(/\S+/g) || [""];
        // on les comptes
        t = evts.length;
        // On créé chaque évenement un par un
        while (t--) {
            evt = evts[t];
            if (obj.addEventListener) {
                // W3C method
                obj.addEventListener(evt, handler, options);
            } else if (obj.attachEvent) {
                // IE method.
                obj.attachEvent('on' + evt, handler);
            } else {
                // Old school method.
                obj['on' + evt] = handler;
            }
        }
    })
}

/*
 * Ajouter un ou plusieurs evenement
 */
function off(objs, events, handler) {

    if (objs === null) {
        return false;
    }

    objs = HTMLForForeach(objs);
    objs.forEach(function (obj) {
        var t, evt, evts = events;
        // on verifie si il y a plusieurs élements
        evts = (evts || "").match(/\S+/g) || [""];
        // on les comptes
        t = evts.length;
        // On créé chaque évenement un par un
        while (t--) {
            evt = evts[t];

            if (obj.removeEventListener) {
                obj.removeEventListener(evt, handler, false);
            }
            if (obj.detachEvent) {
                obj.detachEvent('on' + evt, handler);
            }

        }
    });
}

// create a one-time event
function one(el, type, fn) {
    function handler(event) {
        off(el, type, handler);
        fn(event);
    }

    on(el, type, handler);
}

function fireEvent(objs, evts, options = {}) {
    var t, evt;

    if (objs === null) {
        return false;
    }

    // on verifie si il y a plusieurs élements
    evts = (evts || "").match(/\S+/g) || [""];

    objs = HTMLForForeach(objs);
    objs.forEach(function (obj) {

        if (obj === null || typeof obj === "undefined") {
            return false;
        }

        // on les comptes
        t = evts.length;
        // On créé chaque évenement un par un
        while (t--) {
            evt = evts[t];
            if (typeof Event === 'function' || !document.fireEvent) {
                var event = document.createEvent('HTMLEvents');
                event.initEvent(evt, true, true);
                Object.assign(event, options);
                obj.dispatchEvent(event);
            } else {
                obj.fireEvent('on' + evt);
            }
        }
    });
}

function lastElementInSelector(selector) {
    let selectorMultiple = selector.split(',');
    let lastSelector = [];
    for (let thisSelector in selectorMultiple) {
        if (selectorMultiple.hasOwnProperty(thisSelector)) {
            let selectorTree = selectorMultiple[thisSelector].split(' ');
            lastSelector.push(selectorTree.pop());
        }
    }

    return lastSelector.join(',');
}

function eventIsOnSelector(event, selector) {

    if (!isElement(event.target)) {
        return false;
    }
    var DOM = event.target.matches(selector) ? event.target : event.target.closest(selector);
    if (DOM === null) {
        return false;
    }

    return DOM;
}

/*
 * Ajouter une classe
 * sources:  http://youmightnotneedjquery.com/
 */
function addClass(els, classNames) {
    var t, className;

    if(!Array.isArray(classNames)) {
        // on verifie si il y a plusieurs élements
        classNames = (classNames || "").match(/\S+/g) || [""];
    }

    var elementsTemp = els;
    els = HTMLForForeach(els);
    els.forEach(function (el) {
        // on les comptes
        t = classNames.length;
        // On créé chaque évenement un par un
        while (t--) {
            className = classNames[t];
            if (el.classList) {
                el.classList.add(className);
            } else {
                el.className += ' ' + className;
            }

        }
    });
    return elementsTemp;
}

/*
 * Supprimer une Classe
 * sources:  http://youmightnotneedjquery.com/
 */
function removeClass(els, classNames, regex = false) {

    // on verifie si il y a plusieurs élements
    if(typeof classNames === "string") {
        classNames = (classNames || "").match(/\S+/g) || [""];
    }

    if(!Array.isArray(classNames)) {
        classNames = Array.from(classNames)
    }

    var elementsTemp = els;
    els = HTMLForForeach(els);
    // on les comptes
    els.forEach(function (el) {
        if (el.classList) {
            if (regex) {
                const classes = Array.from(el.classList);
                const search = new RegExp(classNames.join('|'));
                const removeClasses = classes.filter(x => search.test(x));
                el.classList.remove(...removeClasses);
            } else {
                el.classList.remove(...classNames);
            }
        } else {
            let t = classNames.length;
            // On créé chaque évenement un par un
            while (t--) {
                el.className = el.className.replace(new RegExp('(^|\\b)' + classNames[t].split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    });
    return elementsTemp;
}

/*
 *  Savoir si il y une classe
 *
 */
function hasClass(el, className) {
    className = className.replace('.', '');
    if (el.classList) {
        return el.classList.contains(className);
    } else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    }
}


/*
 * Couper un texte si il est trop long
 * avec des ...
 *
 *
 */
function cutString(s, maxLength) {
    if (s.length > maxLength) {
        return s.substr(0, (maxLength - 3)) + '...';
    } else {
        return s;
    }
}

function IDGenerator(idLength) {
    this.length = (typeof idLength === "undefined" ? 8 : idLength);
    this.timestamp = +new Date;

    var _getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    this.generate = function () {
        var ts = this.timestamp.toString();
        var parts = ts.split("").reverse();
        var id = "";

        for (var i = 0; i < this.length; ++i) {
            var index = _getRandomInt(0, parts.length - 1);
            id += parts[index];
        }
        return id;
    }
}

/*
 * Recursively merge properties of two objects
 * sources : http://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
 */
function mergeRecursive(obj1, obj2) {

    for (var p in obj2) {
        try {
            // Property in destination object set; update its value.
            if (obj2[p].constructor == Object) {
                obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
            } else {
                obj1[p] = obj2[p];

            }

        } catch (e) {
            // Property in destination object not set; create it and set its value.
            obj1[p] = obj2[p];

        }
    }

    return obj1;
}

/*
 * Retourne la position de l'élément par rapport à la page
 * @param {elementDOM} element
 * @returns {main_L1.cmonsiteEditor.prototype.getPosition.mainAnonym$3}
 */
function getPosition(element) {
    var left = 0;
    var top = 0;

    /*Tant que l'on a un élément parent*/
    while (typeof element.offsetParent !== "undefined" && element.offsetParent !== null) {
        /*On ajoute la position de l'élément parent*/
        left += element.offsetLeft + (element.clientLeft !== null ? element.clientLeft : 0);
        top += element.offsetTop + (element.clientTop !== null ? element.clientTop : 0);
        element = element.offsetParent;
    }
    return {x: left, y: top};
}

/**
 * Retourne l'élement par tagname
 * @param {type} element
 * @param {type} tagname
 * @returns {unresolved}
 */
function closest(element, tagname) {
    tagname = tagname.toLowerCase();
    do {
        if (element.nodeName.toLowerCase() === tagname)
            return element;
    } while (element = element.parentNode);

    return null;
}

/**
 * Si le tableau contien la valeur retourne vrai
 * @param {type} arr
 * @param {type} v
 * @returns {Boolean}
 */
function arrayIncludes(arr, v) {
    var i = 0;
    var n = arr.length;
    for (; i < n; ++i) {
        var val = arr[i];
        if (val == v) {
            return true;
        }
    }
    return false;
}

/**
 * Retourne le parent en cherchant par class
 * @param {type} el
 * @param {type} clz
 * @returns {Array.parentNode}
 */
function closestByClass(el, clz) {
    clz = clz.replace('.', '');
    var classesMatch = clz.split(/\s+/);
    while (el && el != document) {
        var p = el.parentNode;
        var classes = ((p && typeof p.className !== "object" && p.className) || '').split(/\s+/);
        var isMatch = false;

        isMatch = classesMatch.some(function (value) {
            return arrayIncludes(classes, value)
        });

        if (isMatch) {
            return p;
        } else {
            el = p;
        }
    }
    return null;
}

/**
 * Retourne le parent en cherchant par class
 * @param {Object} el
 * @param {String} classe
 * @param {String} EndParentClass
 * @returns {Array.parentNode}
 */
function parentByClass(el, classe, EndParentClass) {
    EndParentClass = typeof EndParentClass === "undefined" ? 'body' : EndParentClass;
    var matchElementsDOM = [];

    while (el && !el.matches(EndParentClass) && el.parentNode !== null) {
        var p = el.parentNode;

        var isMatch = p.matches(classe);
        if (isMatch) {
            matchElementsDOM.push(p);
        }

        el = p;
    }
    return matchElementsDOM;
}

/**
 *
 * @param method
 * @param scope
 * @returns {Function}
 */
function proxy(method, scope) {
    var args = Array.prototype.slice.call(arguments, 2);
    return function () {
        return method.apply(scope, args.concat(Array.prototype.slice.call(arguments)));
    }
}


/**
 * forEach method, could be shipped as part of an Object Literal/Module
 * http://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
 *
 * @param {array} array
 * @param {function} callback
 * @param {type} scope
 * @returns {undefined}
 */
function forEach(array, callback, scope) {
    var lengthArray = array.length, isObject = false;
    if (lengthArray === undefined) {
        isObject = true;
        lengthArray = Object.keys(array).length;
    }

    for (var i = 0; i < lengthArray; i++) {
        var index = isObject ? Object.keys(array)[i] : i;
        var element = isObject ? array[Object.keys(array)[i]] : array[i];
        callback.call(scope, index, element); // passes back stuff we need
    }
}


//Returns true if it is a DOM node
function isNode(o) {
    return (
        typeof Node === "object" ? o instanceof Node :
            o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
    );
}

function isWindow(o) {
    return (
        Object.prototype.toString.call(o) === "[object Window]" ||
        Object.prototype.toString.call(o) === "[object global]"
    );

}


//Returns true if it is a DOM element
function isElement(o) {
    return (
        typeof o === "object" && o.nodeType === 1 && typeof o.nodeName === "string"
    );
}

// http://stackoverflow.com/questions/4619668/executing-script-inside-div-retrieved-by-ajax
function parseScript(_source, _requireSource, documentSource) {
    documentSource = (typeof documentSource === "undefined" ? document : documentSource);
    var source = _source;
    var scripts = [];
    var src = [];
    var regexSrc = /src=['|"](\S*)['|"]/;

    var requireSource = (typeof _requireSource === "undefined" ? [] : _requireSource.slice(0));
    // Strip out tags
    while (source.toLowerCase().indexOf("<script") > -1 || source.toLowerCase().indexOf("</script") > -1) {
        var s = source.toLowerCase().indexOf("<script");
        var s_e = source.indexOf(">", s);
        var e = source.toLowerCase().indexOf("</script", s);
        var e_e = source.indexOf(">", e);

        // Add to scripts array
        // On supprime le document ready
        var sourceTmp = source.substring(s_e + 1, e);
        var docReadyRegex = new RegExp(/([\s\S]*)\$\(document\)\.ready\(function\s*\(\)\s*\{([\s\S]*)\}\)\;([\s\S]*)/, 'mi');
        var resultNotReadyTmp = docReadyRegex.exec(sourceTmp);
        var resultNotReady = '';
        if (resultNotReadyTmp !== null) {
            resultNotReadyTmp.shift();
            resultNotReadyTmp.forEach(function (sourceElem) {
                resultNotReady += sourceElem;
            });
        } else {
            resultNotReady = sourceTmp;
        }

        src.push(source.substring(s, s_e));
        scripts.push(resultNotReady);

        // Strip from source
        source = source.substring(0, s) + source.substring(e_e + 1);
    }

    if (!requireSource.length) {
        for (var i = 0; i < scripts.length; i++) {
            if (scripts[i] == '' && regexSrc.test(src[i])) {
                requireSource.push(regexSrc.exec(src[i])[1]);
            }
        }
    }

    // Loop through every script collected and eval it
    var requiresrc = '';
    for (var i = 0; i < requireSource.length; i++) {
        var prefix = (requiresrc === '' ? '' : ',');
        requiresrc = prefix + '"' + requireSource[i] + '"';
    }


    for (var i = 0; i < scripts.length; i++) {
        try {
            var sc = documentSource.createElement("script");
            sc.type = "text/javascript";
            if (scripts[i] != '' || requiresrc != '') {
                var prefixRequire = 'requirejs([' + requiresrc + '], function(){ ';
                var postfixRequire = ' });';
                sc.textContent = prefixRequire + scripts[i] + postfixRequire;
            }

            documentSource.body.appendChild(sc);
        } catch (e) {
            // do what you want here when a script fails
            // window.alert('Script failed to run - '+scripts[i]);
            if (e instanceof SyntaxError) console.log(e.message + ' - ' + scripts[i]);
        }
    }
// Return the cleaned source
    return source;
}

function loadCss(url) {
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
}

function loadScript({type = 'text/javascript', href = '', defer = false, async = false, context = document}) {

    const dataScript = {
        'type': type,
        'href': href,
    }

    if (defer) {
        dataScript['defer'] = '';
    }

    if (async) {
        dataScript['async'] = '';
    }

    return createElement([{'script': dataScript}], context.getElementsByTagName("head")[0]);
}


function insertTextAtCursor(text) {
    var sel, range, html;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode(text));
        }
    } else if (document.selection && document.selection.createRange) {
        document.selection.createRange().text = text;
    }
}

function isNativePointer(pointer) {
    return pointer instanceof window.Event || supportsTouch && window.Touch && pointer instanceof window.Touch
}

function getXY(type, pointer, xy) {
    xy = xy || {};
    type = type || "page";
    xy.x = pointer[type + "X"];
    xy.y = pointer[type + "Y"];
    return xy
}

var isOperaMobile = navigator.appName == "Opera" && supportsTouch && navigator.userAgent.match("Presto");

function closestInteract(child, selector) {
    var parent = parentElement(child);
    while (isElement(parent)) {
        if (matchesSelector(parent, selector)) {
            return parent
        }
        parent = parentElement(parent)
    }
    return null
}

function parentElement(node) {
    var parent = node.parentNode;
    if (isDocFrag(parent)) {
        while ((parent = parent.host) && isDocFrag(parent)) {
        }
        return parent
    }
    return parent
}

function getWindow(node) {
    if (isWindow(node)) {
        return node
    }
    var rootNode = node.ownerDocument || node;
    return rootNode.defaultView || rootNode.parentWindow || window
}

function isElementInteract(o) {
    if (!o || typeof o !== "object") {
        return false
    }
    var _window = getWindow(o) || window;
    return /object|function/.test(typeof _window.Element) ? o instanceof _window.Element : o.nodeType === 1 && typeof o.nodeName === "string"
}

function getElementClientRect(element) {
    var clientRect = element instanceof SVGElement ? element.getBoundingClientRect() : element.getClientRects()[0];
    return clientRect && {
        left: clientRect.left,
        right: clientRect.right,
        top: clientRect.top,
        bottom: clientRect.bottom,
        width: clientRect.width || clientRect.right - clientRect.left,
        height: clientRect.height || clientRect.bottom - clientRect.top
    }
}

function getScrollXY(win) {
    win = win || window;
    return {x: win.scrollX || win.document.documentElement.scrollLeft, y: win.scrollY || win.document.documentElement.scrollTop}
}

function getElementRect(element) {
    var clientRect = getElementClientRect(element);
    if (!isIOS7 && clientRect) {
        var scroll = getScrollXY(getWindow(element));
        clientRect.left += scroll.x;
        clientRect.right += scroll.x;
        clientRect.top += scroll.y;
        clientRect.bottom += scroll.y
    }
    return clientRect
}

function trySelector(value) {
    if (!(typeof value === "string")) {
        return false
    }
    document.querySelector(value);
    return true
}

function getOriginXY(target, element, action) {

    var actionOptions = target.options[action];
    var actionOrigin = actionOptions && actionOptions.origin;
    var origin = actionOrigin || target.options.origin;

    var originRect = rectUtils.resolveRectLike(origin, target, element, [target && element]);

    return rectUtils.rectToXY(originRect) || {x: 0, y: 0};

    // var origin = interactable ? interactable.options.origin : defaultOptions.origin;
    // if (origin === "parent") {
    //     origin = parentElement(element)
    // } else if (origin === "self") {
    //     origin = interactable.getRect(element)
    // } else if (trySelector(origin)) {
    //     origin = closestInteract(element, origin) || {x: 0, y: 0}
    // }
    // if (typeof origin === "function") {
    //     origin = origin(interactable && element)
    // }
    // if (isElementInteract(origin)) {
    //     origin = getElementRect(origin)
    // }
    // origin.x = "x" in origin ? origin.x : origin.left;
    // origin.y = "y" in origin ? origin.y : origin.top;
    // return origin
}

function getPageXY(pointer, page) {
    page = page || {};
    if (isOperaMobile && isNativePointer(pointer)) {
        getXY("screen", pointer, page);
        page.x += window.scrollX;
        page.y += window.scrollY
    } else {
        getXY("page", pointer, page)
    }
    return page
}


function scrollIt(destination, offset, easing, context) {
    easing = typeof easing === "undefined" ? 'smooth' : easing;
    context = typeof context === "undefined" ? window : context;
    offset = typeof offset === "undefined" ? 0 : offset;

    // Sécurité pour eviter un bug de blocage de scroll
    context.document.documentElement.style.height = '';

    var destinationOffset = 0;

    if (typeof destination !== 'number') {
        destinationOffset = getElementRect(destination).top - offset;
    }

    context.scroll({
        top: destinationOffset,
        left: 0,
        behavior: easing
    });

}

function whichTransitionEvent() {
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'MozTransition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd'
    };

    for (t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
}

function empty(element) {
    while (element.firstChild) element.removeChild(element.firstChild);
}


function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

function textNodesUnder(node) {
    var all = [];
    for (node = node.firstChild; node; node = node.nextSibling) {
        if (node.nodeType == 3) all.push(node);
        else all = all.concat(textNodesUnder(node));
    }
    return all;
}


/**
 *
 * @param selector string
 * @param context
 * @returns Array
 */
function selectorAll(selector, context) {
    context = context || document;
    var elements = context.querySelectorAll(selector);
    return Array.prototype.slice.call(elements);
}

const regexAttributeNamespace = /([^:]*):([^:]*)/;

const namespaceLink = {
    'xlink': "http://www.w3.org/1999/xlink"
}

function nameSpaceXmlLink(namespace) {
    if (namespaceLink.hasOwnProperty(namespace)) {
        return namespaceLink[namespace];
    } else {
        return namespace;
    }
}

/**
 *
 * @param dataJson
 * @param parentElement
 * @param prepend
 * @param type
 * @returns {boolean|[]|*}
 */
function createElement(dataJson, parentElement, prepend, type) {
    var returnData = [];
    type = typeof type === "undefined" ? null : type;
    parentElement = typeof parentElement === "undefined" ? null : parentElement;
    prepend = typeof prepend === "undefined" ? false : prepend;

    dataJson.forEach(function (element) {
        if (typeof element !== "undefined") {
            for (var typeElement in element) {
                if (element.hasOwnProperty(typeElement)) {
                    let thisType = 'html';

                    if (type === 'svg') {
                        thisType = type
                    } else {
                        if (typeElement === 'svg') {
                            thisType = typeElement;
                        } else {
                            thisType = 'html';
                        }
                    }

                    let elem;

                    try {
                        if (thisType === 'svg') {
                            elem = document.createElementNS("http://www.w3.org/2000/svg", typeElement);
                        } else {
                            elem = document.createElement(typeElement);
                        }
                    } catch (e) {
                        console.log('Erreur creation element', typeElement, e);
                        continue;
                    }

                    var elemJSON = element[typeElement];
                    for (var attribute in elemJSON) {
                        if (elemJSON.hasOwnProperty(attribute)) {
                            switch (attribute) {
                                case 'children':

                                    if (Array.isArray(elemJSON[attribute])) {
                                        createElement(elemJSON[attribute], elem, undefined, thisType);
                                    }

                                    if (isNode(elemJSON[attribute])) {
                                        elem.appendChild(elemJSON[attribute]);
                                    }

                                    break;
                                case 'textContent':
                                    elem.textContent = elemJSON[attribute];
                                    break;
                                case 'innerHTML':
                                    elem.innerHTML = elemJSON[attribute];
                                    break;
                                case 'class':
                                    if (Array.isArray(elemJSON[attribute])) {
                                        elemJSON[attribute].forEach(function (thisClass) {
                                            if (thisClass) {
                                                elem.classList.add(thisClass);
                                            }
                                        });
                                    } else {
                                        elem.setAttribute(attribute, elemJSON[attribute]);
                                    }
                                    break;
                                default:
                                    let withNamespace = regexAttributeNamespace.exec(attribute);
                                    if (withNamespace) {
                                        elem.setAttributeNS(withNamespace[1], withNamespace[2], elemJSON[attribute]);
                                        elem.setAttributeNS(nameSpaceXmlLink(withNamespace[1]), withNamespace[2], elemJSON[attribute]);
                                    } else {
                                        elem.setAttribute(attribute, elemJSON[attribute]);
                                    }
                                    break;
                            }
                        }
                    }

                    returnData.push(elem);

                    if (!!parentElement) {

                        if (prepend && parentElement.children.length) {
                            parentElement.insertBefore(elem, parentElement.firstChild);
                        } else {
                            parentElement.appendChild(elem);
                        }

                    }
                }
            }
        }
    });

    if (returnData.length) {

        switch (returnData.length) {
            case 1:
                return returnData[0];
                break;
            default:
                return returnData;
                break;
        }

    } else {
        return false;
    }


}

function getCssPropertyForRule(rule, prop, context) {
    context = typeof context === "undefined" ? document : context;
    var sheets = context.styleSheets;
    var slen = sheets.length;
    for (var i = 0; i < slen; i++) {
        var rules = context.styleSheets[i].cssRules;
        var rlen = rules.length;
        for (var j = 0; j < rlen; j++) {
            if (rules[j].selectorText == rule) {
                return rules[j].style[prop];
            }
        }
    }
}


function childrenSelectorAll(element, selector) {
    var childrenMatch = [];

    if (element.hasChildNodes()) {
        Array.prototype.forEach.call(element.children, function (children) {
            if (children.matches(selector)) {
                childrenMatch.push(children);
            }
        })
    }

    return childrenMatch;
}

function childrenSelector(element, selector) {
    var childrenMatch = childrenSelectorAll(element, selector);
    var children = childrenMatch.shift();

    return typeof children === "undefined" ? null : children;
}

/**
 *
 * @param handler function
 * @param params array
 */
function bindToEventHandler(handler, params) {
    var boundParameters = Array.prototype.slice.call(params);
    //create closure
    return function (e) {
        boundParameters.unshift(e);
        handler.apply(this, boundParameters);
    }
}

const Helper = {
    on: on,
    off: off,
    one: one,
    fireEvent: fireEvent,
    addClass: addClass,
    removeClass: removeClass,
    hasClass: hasClass,
    cutString: cutString,
    mergeRecursive: mergeRecursive,
    getPosition: getPosition,
    closest: closest,
    closestByClass: closestByClass,
    forEach: forEach,
    IDGenerator: IDGenerator,
    proxy: proxy,
    isNode: isNode,
    isElement: isElement,
    parseScript: parseScript,
    loadCss: loadCss,
    insertTextAtCursor: insertTextAtCursor,
    getOriginXY: getOriginXY,
    getPageXY: getPageXY,
    getWindow: getWindow,
    getElementRect: getElementRect,
    scrollIt: scrollIt,
    whichTransitionEvent: whichTransitionEvent,
    empty: empty,
    debounce: debounce,
    HTMLForForeach: HTMLForForeach,
    rectUtils: rectUtils,
    wait: wait,
    textNodesUnder: textNodesUnder,
    selectorAll: selectorAll,
    parentByClass: parentByClass,
    createElement: createElement,
    eventIsOnSelector: eventIsOnSelector,
    childrenSelector: childrenSelector,
    childrenSelectorAll: childrenSelectorAll,
    bindToEventHandler: bindToEventHandler,
    lastElementInSelector: lastElementInSelector,
    loadScript
};

if (typeof window !== 'undefined') {
    window.Helper = Helper;
}

// explicitly return public methods when this object is instantiated
export {
    on,
    off,
    one,
    fireEvent,
    addClass,
    removeClass,
    hasClass,
    cutString,
    mergeRecursive,
    getPosition,
    closest,
    closestByClass,
    forEach,
    IDGenerator,
    proxy,
    isNode,
    isElement,
    parseScript,
    loadCss,
    insertTextAtCursor,
    getOriginXY,
    getPageXY,
    getWindow,
    getElementRect,
    scrollIt,
    whichTransitionEvent,
    empty,
    debounce,
    HTMLForForeach,
    rectUtils,
    wait,
    textNodesUnder,
    selectorAll,
    parentByClass,
    createElement,
    eventIsOnSelector,
    childrenSelector,
    childrenSelectorAll,
    bindToEventHandler,
    lastElementInSelector,
    loadScript
};


export default Helper;
