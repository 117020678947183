
function cleanForHTMLClass(str) {
    let regex = /[^a-zA-Z0-9-_]/g;
    str = str.replace(regex, '');
    return str;
}

function uniqId(prefix) {
    let n, base, ext, now;

    if (window.performance) {
        var s = performance.timing.navigationStart;
        n = performance.now();
        base = Math.floor((s + Math.floor(n))/1000);
    } else {
        n = new Date().getTime();
        base = Math.floor(n/1000);
    }
    ext = Math.floor(n%1000*1000);
    now = ("00000000"+base.toString(16)).slice(-8)+("000000"+ext.toString(16)).slice(-5);
    if (now <= window.my_las_uid) {
        now = (parseInt(window.my_las_uid?window.my_las_uid:now, 16)+1).toString(16);
    }
    window.my_las_uid = now;
    return (prefix?prefix:'')+now;
}

export {uniqId, cleanForHTMLClass}
