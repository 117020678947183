import $ from 'jquery';

import ProductSelect from './components/product-select';

// import Swiper JS
// core version + navigation, pagination modules:
// import Swiper from 'swiper/bundle';
// Import Swiper and modules
import Swiper, {Lazy, Navigation, Pagination} from 'swiper';
Swiper.use([Lazy, Navigation, Pagination]);

import {uniqId} from "./Helper/Str";
import {EventOn} from "./Helper/Event";


createInputFile();


if (isMobile) {
    //LauchSliderImageMobile()
} else {

}

coverImage();
productsListLauch();

prestashop.on('updatedProduct', (event) => {
    createInputFile();
    coverImage();
    if (event && event.product_minimal_quantity) {
        const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
        const quantityInputSelector = prestashop.selectors.quantityWanted;
        const quantityInput = $(quantityInputSelector);

    }

    $($(prestashop.themeSelectors.product.activeTabs).attr('href')).addClass('active').removeClass('fade');
    $(prestashop.themeSelectors.product.imagesModal).replaceWith(event.product_images_modal);

    const productSelect = new ProductSelect();
    productSelect.init();
});



/**
 *
 * @param url
 * @param DOMElement
 * @returns {Promise<unknown>}
 */
function changeImageSource(url, DOMElement) {
    return new Promise((resolve, reject) => {
        DOMElement.onload = () => resolve(DOMElement);
        DOMElement.onerror = reject;
        DOMElement.src = url;
    })
}


var isMobile = $(window).width() < 768;

var productImages = document.querySelector('.product-container__slider .images-container');
if(productImages !== null) {
    var productImagesClone = productImages.cloneNode(true);
}

function LauchSliderImageMobile() {
    var productImageBlock = document.querySelector('.product-container__slider');
    let imageCover = null;


    if (productImageBlock !== null && productImagesClone.children.length > 1) {
        var slider = document.createElement('div');
        slider.classList.add('product-images__slider');

        var swiperContainer = document.createElement('div');
        swiperContainer.classList.add('swiper');

        var swiperButton = document.createElement('div');
        var swiperButtonLeft = swiperButton.cloneNode(true);
        var swiperButtonRight = swiperButton.cloneNode(true);
        swiperButtonLeft.classList.add('swiper-button-prev');
        swiperButtonRight.classList.add('swiper-button-next');

        productImagesClone.classList.add('swiper-wrapper');
        productImagesClone.classList.remove(['images-container', 'js-images-container']);


        var swiperSlider = document.createElement('div');
        swiperSlider.classList.add('swiper-slide');

        var images = productImagesClone.querySelectorAll('.product-cover, .thumb-container');
        imageCover = productImagesClone.querySelector('.product-cover').cloneNode(true);
        Array.prototype.forEach.call(images, function (elem) {
            var slide = swiperSlider.cloneNode(true);
            productImagesClone.appendChild(slide);
            slide.appendChild(elem);
            const img = elem.querySelector('img');
            changeImageSource(img.dataset.imageMediumSrc, img);

        });

        productImageBlock.insertBefore(slider, productImages);
        slider.appendChild(imageCover);
        slider.appendChild(swiperContainer);
        swiperContainer.appendChild(productImagesClone);
        slider.appendChild(swiperButtonLeft);
        slider.appendChild(swiperButtonRight);

        let mainSwiper = new Swiper(swiperContainer, {
            slidesPerView: 3,
            slidesPerGroup: 1,
            slidesPerGroupSkip: 1,
            spaceBetween: 14,
            effect: 'slide',
            loop: true,
            centeredSlides: true,
            preloadImages: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.product-images__slider .swiper-button-next',
                prevEl: '.product-images__slider .swiper-button-prev',
            },
        });

        mainSwiper.on('slideChange', changeBigPicture);
        mainSwiper.on('click', changeBigPicture);


        function changeBigPicture(swiper, event) {
            let thumb;

            if(typeof event === "undefined") {

                let slide = swiper.slides[swiper.snapIndex];
                thumb = slide.querySelector('.thumb, img');

                if(thumb) {
                    const imageCoverImg = imageCover.querySelector('img');
                    changeImageSource(typeof thumb.dataset.imageLargeSrc !== "undefined" ? thumb.dataset.imageLargeSrc : thumb.getAttribute('src'), imageCoverImg);
                }
            } else {
                if(typeof swiper.clickedIndex === "number") {
                    swiper.slideTo(swiper.clickedIndex, swiper.params.speed, function() {
                        swiper.updateSlidesClasses();
                        swiper.updateSize();
                    });

                }
            }

        }

    }

}


function coverImage() {
    const productCover = document.querySelector(prestashop.themeSelectors.product.cover);
    
    let productImagesSlider = document.querySelector('.product-cover');
    let productThumbImagesSlider = document.querySelector('.product-images__slider .swiper');
    if (productImagesSlider !== null) {
        
        const mainSwiper = new Swiper(productImagesSlider, {
            slidesPerView: 1,
            effect: 'slide',
            loop: false,
            centeredSlides: true,
            preloadImages: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.product-cover .swiper-button-next',
                prevEl: '.product-cover .swiper-button-prev',
            },
        });
        const thumbSwiper = new Swiper(productThumbImagesSlider, {
            slidesPerView: 3,
            spaceBetween: 18,
            effect: 'slide',
            loop: false,
            preloadImages: true,
            direction: 'vertical',
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.product-images__block .swiper-button-next',
                prevEl: '.product-images__block .swiper-button-prev',
            }
        });
        
        //mainSwiper.on('slideChange', changeBigPicture);
        //mainSwiper.on('click', changeBigPicture);
        
        mainSwiper.on('slideChange', function () {
            thumbSwiper.slideTo(this.realIndex );
            
            
            const slide = this.slides[this.realIndex];
            
            if(slide.classList.contains('swiper-slide-video')) {
                const thumb = slide.querySelector('img');
                
                if (thumb && thumb.dataset.hasOwnProperty('video')) {
                    
                    var video = document.createElement('video');
                    video.setAttribute('width', '606');
                    video.setAttribute('height', '606');
                    video.setAttribute('controls', true);
                    video.setAttribute('autoplay', true);
                    video.setAttribute('loop', true);
                    video.setAttribute('poster', thumb.dataset.imageLargeSrc);
                    
                    var source = document.createElement('source');
                    source.setAttribute('src', thumb.dataset.video);
                    source.setAttribute('type', thumb.dataset.mime);
                    
                    video.appendChild(source);
                    
                    slide.appendChild(video);
                } else {
                    const video = slide.querySelector('video');
                    if(video !== null) {
                        video.remove();
                    }
                }
            }
            
        });
        
        thumbSwiper.on('click', function (swiper) {
            mainSwiper.slideTo(swiper.clickedIndex);
            event.preventDefault();
        });
        
        
        $('.product__audio-link').on('click', function (event) {
            const slideVideo = productThumbImagesSlider.querySelector('.swiper-slide-video');
            if(slideVideo !== null) {
                const index = Array.from(slideVideo.parentNode.children).indexOf(slideVideo);
                
                mainSwiper.slideTo(index);
            }
            
            
            event.preventDefault();
            event.stopPropagation();
            return false;
        })
        
    }


}


function productsListLauch() {
    const productsLists = document.querySelectorAll(prestashop.themeSelectors.product.slider);

    if (productsLists !== null) {
        Array.prototype.forEach.call(productsLists, function (productsList) {

            const idProductList = uniqId('products-slider');
            productsList.setAttribute('id', idProductList);


            new Swiper('#' + idProductList, {
                modules: [Navigation, Pagination, Lazy],
                navigation: {
                    nextEl: '#' + idProductList + ' .swiper-button-next',
                    prevEl: '#' + idProductList + ' .swiper-button-prev',
                },
                slidesPerView: 4,
                spaceBetween: 30,
                loop: true,
                preloadImages: true,
                lazy: {
                    loadPrevNext: true,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 15
                    }
                }
            });

        });
    }


}


function createInputFile() {
    $(prestashop.themeSelectors.fileInput).on('change', (event) => {
        let target;
        let file;

        // eslint-disable-next-line
        if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
            $(target).prev().text(file.name);
        }
    });
}


function stickyCheck() {
    const productInfos = document.querySelector('.product-container__infos');
    if(productInfos !== null) {
        const productInfosRect = productInfos.getBoundingClientRect();
        const top = window.getComputedStyle(productInfos, null).getPropertyValue('top');
        if(window.outerHeight > (productInfosRect.height + parseInt(top))) {
            productInfos.classList.add('sticky');
        } else {
            productInfos.classList.remove('sticky');
        }
    }
}


const eventResize = {
    'element': window,
    'type': 'resize',
    'listener': function () {
        stickyCheck();
    },
    'options': false,
};

EventOn(eventResize);

stickyCheck();

