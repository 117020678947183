import $ from 'jquery';

import 'bootstrap-touchspin';
import {eventIsOnSelector, EventOn} from "../Helper/Event";
import {createElement} from "../Helper/Helper";

const promises = [];


const abortPreviousRequests = () => {
    let promise;
    while (promises.length > 0) {
        promise = promises.pop();
        promise.abort();
    }
};

prestashop.blockcart = prestashop.blockcart || {};

function searchProductInListCart(id, product) {
    return parseInt(product.id) === parseInt(id)
}

prestashop.blockcart.getProductCart = function (id) {
    if (typeof prestashop.cart === 'undefined') {
        return false;
    }
    return prestashop.cart.products.find(searchProductInListCart.bind(null, id));
}

function runTouchSpinInShoppingCart() {

    let blockcartInputQuantity = document.querySelectorAll(".blockcart-item__quantity, .js-cart-line-product-quantity");
// Affiche de l'input de quantité avec les boutons + / -
    $(blockcartInputQuantity).TouchSpin({
        verticalbuttons: true,
        verticalupclass: 'plus',
        verticaldownclass: 'minus'
    });

    $(blockcartInputQuantity).on('change', function (event) {
        const input = this;
        updateProductQuantityInCart(event.target);
    })


    let thisElementTimeout;

    const blockcart = document.querySelector('.blockcart');

    let eventMouseEnter = {
        'element': blockcart,
        'type': 'mouseenter',
        'listener': function (event) {

            if (!isTouch) {
                thisElementTimeout = mouseenter(this, event, self, thisElementTimeout);
            }


        }
    };

    EventOn(eventMouseEnter);


    let eventMouseLeave = {
        'element': blockcart,
        'type': 'mouseleave',
        'listener': function (event) {

            if (!isTouch) {
                thisElementTimeout = mouseleave(this, event, self, thisElementTimeout);
            }

        }
    };

    EventOn(eventMouseLeave);


}


function getQuantityChangeType($quantity) {
    return $quantity > 0 ? 'up' : 'down';
}

function getRequestData(quantity) {
    return {
        ajax: '1',
        qty: Math.abs(quantity),
        action: 'update',
        op: getQuantityChangeType(quantity),
    };
}


function updateProductQuantityInCart(input) {

    const optionsDefault = {
        updateUrl: '',
        productId: 0,
    }

    let options = Object.assign(optionsDefault, input.dataset);

    const updateQuantityInCartUrl = options.updateUrl;
    const productId = options.productId;
    const productInCart = prestashop.blockcart.getProductCart(productId);

    if (!productInCart) {
        return;
    }
    const baseValue = productInCart.quantity;

    // There should be a valid product quantity in cart
    const targetValue = input.value;

    /* eslint-disable */
    if (targetValue != parseInt(targetValue, 10) || targetValue < 0 || isNaN(targetValue)) {
        input.value = 0;
        return;
    }
    /* eslint-enable */
    // There should be a new product quantity in cart
    const qty = targetValue - baseValue;

    if (qty === 0) {
        return;
    }

    sendUpdateQuantityInCartRequest(updateQuantityInCartUrl, getRequestData(qty), input);
}

let cartIsOpen = false;

const alert = document.createElement('div');
alert.classList.add(...['alert', 'alert-primary']);

alert.appendChild(document.createElement('span'));
const buttonAlert = document.createElement('button');
buttonAlert.classList.add('btn-close');
buttonAlert.setAttribute('aria-label', 'Close');
buttonAlert.dataset.bsDismiss = "alert";
alert.appendChild(buttonAlert);


function addWaiting() {
    const blockcart = document.querySelector(".blockcart__preview");

    createElement([{
        'div': {
            'class': 'waiting', children: [{
                'div': {'class': 'waiting__icon'}
            }]
        }
    }], blockcart);

}

function removeWaiting() {
    const waiting = document.querySelector(".blockcart__preview .waiting");
    if (waiting !== null) {
        waiting.remove();
    }
}


function sendUpdateQuantityInCartRequest(updateQuantityInCartUrl, requestData, input) {
    abortPreviousRequests();

    let blockcart = document.querySelector(".blockcart");
    if (blockcart !== null) {
        if (blockcart.classList.contains('blockcart--open')) {
            cartIsOpen = true;
            addWaiting();
        } else {
            cartIsOpen = false;
            removeWaiting();
        }
    }

    return $.ajax({
        url: updateQuantityInCartUrl,
        method: 'POST',
        data: requestData,
        dataType: 'json',
        beforeSend(jqXHR) {
            promises.push(jqXHR);
        },
    }).then((resp) => {

        input.value = resp.quantity;

        let dataset;

        if (input && input.dataset) {
            // eslint-disable-next-line
            dataset = input.dataset;
        } else {
            dataset = resp;
        }

        removeWaiting()
        if (resp.hasOwnProperty('cart')) {
            prestashop.emit('updateCart', {
                reason: dataset,
                resp,
            });
        } else {
            if (typeof resp.errors === 'object') {
                const blockcart = document.querySelector(".blockcart");
                const subtotal = blockcart.querySelector(".cart-subtotals");
                if (Array.isArray(resp.errors)) {
                    resp.errors.forEach(function (error) {
                        const alertError = alert.cloneNode(true);
                        alertError.querySelector('span').textContent = error;
                        subtotal.parentNode.insertBefore(alertError, subtotal);
                    });
                } else {

                }
            }
        }


        if (resp.hasOwnProperty('errors') && resp.errors !== '') {

            const errors = resp.errors;
            let errorsMessage;
            if (typeof errors === 'object') {
                errorsMessage = errors.join('<br/>');
            } else {
                errorsMessage = errors;
            }

            prestashop.emit('showErrorNextToAddtoCartButton', {errorMessage: errorsMessage});
            prestashop.emit('handleError', {
                eventType: 'updateProductQuantityInCart',
                resp,
            });
        } else {
            // Refresh cart preview
            prestashop.emit('updateCart', {
                reason: dataset,
                resp,
            });
        }


    }).fail((resp) => {
        prestashop.emit('handleError', {
            eventType: 'updateProductQuantityInCart',
            resp,
        });
    });
}

runTouchSpinInShoppingCart();

prestashop.on('updateShoppingCart', function () {
    runTouchSpinInShoppingCart();
    if (cartIsOpen) {
        let blockcart = document.querySelector(".blockcart");
        if (blockcart !== null) {
            blockcart.classList.add('blockcart--open');
        }

        cartIsOpen = false;
    }
});

prestashop.on('updatedCart', function () {
    runTouchSpinInShoppingCart();
    if (cartIsOpen) {
        let blockcart = document.querySelector(".blockcart");
        if (blockcart !== null) {
            blockcart.classList.add('blockcart--open');
        }

        cartIsOpen = false;
    }
});


prestashop.blockcart.showModal = (html) => {
    console.trace(html);

    function getBlockCartModal() {
        return $('#blockcart-modal');
    }

    let $blockCartModal = getBlockCartModal();

    if ($blockCartModal.length) {
        $blockCartModal.remove();
    }

    $('body').append(html);

    $blockCartModal = getBlockCartModal();
    $blockCartModal.modal('show').on('hidden.bs.modal', (event) => {
        prestashop.emit('updateProduct', {
            reason: event.currentTarget.dataset,
            event,
        });
    });
};

prestashop.blockcart.open = function () {
    let blockcart = document.querySelector(".blockcart");
    if (blockcart !== null) {
        blockcart.classList.add('blockcart--open');
    }

}

prestashop.blockcart.close = function () {
    let blockcart = document.querySelector(".blockcart");
    if (blockcart !== null) {
        blockcart.classList.remove('blockcart--open');
    }

}

prestashop.blockcart.toggle = function () {
    let blockcart = document.querySelector(".blockcart");
    if (blockcart !== null) {
        if (blockcart.classList.contains('blockcart--open')) {
            blockcart.classList.remove('blockcart--open');
        } else {
            blockcart.classList.add('blockcart--open');
        }
    }

}


var deviceAgent = navigator.userAgent.toLowerCase();
var isTouchDevice = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
    deviceAgent.match(/(android)/) ||
    deviceAgent.match(/(iemobile)/) ||
    deviceAgent.match(/iphone/i) ||
    deviceAgent.match(/ipad/i) ||
    deviceAgent.match(/ipod/i) ||
    deviceAgent.match(/blackberry/i) ||
    deviceAgent.match(/bada/i));

var windowSmall = window.innerWidth < 992;

const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0) || isTouchDevice || windowSmall);


const sleepOpen = 150;
const sleepClose = 250;

function mouseenter(element, event, self, timeout) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
        prestashop.blockcart.open();
    }, sleepOpen);
    return timeout;
}

function mouseleave(element, event, self, timeout) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
        prestashop.blockcart.close();
    }, sleepClose);
    return timeout;
}

function mousetoggle(element, event, self, timeout) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
        prestashop.blockcart.toggle();
    }, sleepOpen);
    return timeout;
}

let event = {
    'element': document.body,
    'type': 'click',
    'listener': function (event) {
        switch (true) {
            case !!eventIsOnSelector(event, '.blockcart .btn-close'):
                thisElementTimeout = mouseleave(this, event, self, thisElementTimeout);
                break;
            case !!eventIsOnSelector(event, '.blockcart'):

                break;
            default:
                prestashop.blockcart.close();
                break;
        }

    },
    'options': false
}

EventOn(event);






