
import $ from 'jquery';
import prestashop from 'prestashop';
import 'bootstrap-touchspin';
import {EventFire, EventOn} from "./Helper/Event";
import {debounce} from "./Helper/Helper";

function setUpCheckout() {
    $(prestashop.themeSelectors.checkout.termsLink).on('click', (event) => {
        event.preventDefault();
        let url = $(event.target).attr('href');

        if (url) {
            // TODO: Handle request if no pretty URL
            url += '?content_only=1';
            $.get(url, (content) => {
                $(prestashop.themeSelectors.modal)
                    .find(prestashop.themeSelectors.modalContent)
                    .html($(content).find('.page-cms').contents());
            }).fail((resp) => {
                prestashop.emit('handleError', {eventType: 'clickTerms', resp});
            });
        }

        $(prestashop.themeSelectors.modal).modal('show');
    });

    $(prestashop.themeSelectors.checkout.giftCheckbox).on('click', () => {
        $('#gift').collapse('toggle');
    });
}

function toggleImage() {
    // Arrow show/hide details Checkout page
    $(prestashop.themeSelectors.checkout.imagesLink).on('click', function () {
        const icon = $(this).find('i.material-icons');

        if (icon.text() === 'expand_more') {
            icon.text('expand_less');
        } else {
            icon.text('expand_more');
        }
    });
}


if ($('body#checkout').length === 1) {
    setUpCheckout();
    toggleImage();
}

prestashop.on('updatedDeliveryForm', (params) => {
    if (typeof params.deliveryOption === 'undefined' || params.deliveryOption.length === 0) {
        return;
    }
    // Hide all carrier extra content ...
    $(prestashop.themeSelectors.checkout.carrierExtraContent).hide();
    // and show the one related to the selected carrier
    params.deliveryOption.next(prestashop.themeSelectors.checkout.carrierExtraContent).slideDown();
});

prestashop.on('editAddress', (params) => {
    document.body.scrollIntoView({block: "start"});
});



const formFirstConnexion = document.getElementById('customer-before-form');
const formGuest = document.getElementById('checkout-guest-form');

function copyEmailOnOtherForm(event) {

    const email = formGuest.querySelector('input[name="email"]');
    email.value = event.target.value;

    debounce(function () {
        EventFire(email, 'change');
    }, 500);

}

if(formFirstConnexion !== null) {

    const emailFirst = formFirstConnexion.querySelector('input[name="email"]');

    const eventInputEmailKeyUp = {
        'element': emailFirst,
        'type': 'keyup',
        'listener': copyEmailOnOtherForm,
    };
    EventOn(eventInputEmailKeyUp);

    const eventInputEmailChange = {
        'element': emailFirst,
        'type': 'change',
        'listener': copyEmailOnOtherForm,
    };
    EventOn(eventInputEmailChange);


}


