/* eslint-disable */
//import 'tether';

import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/popover';

import 'configurable-date-input-polyfill';

import prestashop from 'prestashop';
import './selectors';
import {eventIsOnSelector, EventOn} from './Helper/Event.js'

import './responsive';
import './checkout';
import './customer';
import './listing';
import './product';
import './cart';

import Form from './components/form';
import ProductMinitature from './components/product-miniature';
import ProductSelect from './components/product-select';

//import './lib/bootstrap-filestyle.min';
//import './lib/jquery.scrollbox.min';

import Swiper, {Lazy, Navigation, Pagination} from 'swiper';
Swiper.use([Lazy, Navigation, Pagination]);


import './components/block-cart';
import $ from 'jquery';

window.$ = $;

/* eslint-enable */

import A11yDialog from 'a11y-dialog';
import TopMenu from "./components/top-menu";
import {uniqId} from "./Helper/Str";
import {scrollIt} from "./Helper/Helper";

window.A11yDialog = A11yDialog;

const form = new Form();

const productMinitature = new ProductMinitature();
const productSelect = new ProductSelect();
const topMenu = new TopMenu();
form.init();
productMinitature.init();
productSelect.init();
topMenu.init();

//
// // import Swiper JS
// // core version + navigation, pagination modules:
// import Swiper from 'swiper/bundle';
//
// let carousel = document.getElementById('carousel');
// let optionsCarouselDefault = {
//     speed: 500,
//     pause: 3000,
// };
//
//
// if (carousel !== null) {
//
//     let optionsCarousel = Object.assign(optionsCarouselDefault, carousel.dataset);
//
//     let mainSwiper = new Swiper(carousel, {
//         speed: parseInt(optionsCarousel.speed),
//         slidesPerView: 1,
//         effect: 'slide',
//         autoplay: {
//             delay: parseInt(optionsCarousel.pause)
//         },
//         pagination: {
//             el: '#carousel .swiper-pagination',
//             type: 'bullets',
//         },
//         // Disable preloading of all images
//         preloadImages: true,
//         // Enable lazy loading
//         lazy: {
//             loadPrevNext: true,
//         },
//         navigation: {
//             nextEl: '#carousel .swiper-button-next',
//             prevEl: '#carousel .swiper-button-prev',
//         },
//         breakpoints: {
//             768: {
//                 paginationClickable: true,
//                 centeredSlides: true
//             }
//         },
//
//     });
//
// }

//
// import Instafeed from './lib/instafeed';
//
// if (typeof LowInstafeed !== "undefined") {
//     let userFeed = new Instafeed({
//         get: LowInstafeed.get,
//         userId: LowInstafeed.userId,
//         clientId: LowInstafeed.clientId,
//         accessToken: LowInstafeed.accessToken,
//         tagName: LowInstafeed.tagName,
//         target: 'instafeed',
//         limit: LowInstafeed.limit,
//         template: '<div class="swiper-slide"><a href="{{link}}" target="_blank" class="instafeed__picture"><img src="{{image}}" /></a></div>',
//         resolution: LowInstafeed.resolution,
//         after: function () {
//             sliderInstagramLauch();
//         },
//         error: function (param) {
//             console.log('param', param);
//         }
//     });
//
//     userFeed.run();
// }
//
//
// function sliderInstagramLauch() {
//     var reponsive = {
//         xs: '767',
//         sm: '991',
//         md: '1263'
//     };
//
//     // Si on a bien Swiper API de chargé
//     if (typeof Swiper === "function") {
//
//         var settings = {
//             grabCursor: true,
//             navigation: {
//                 nextEl: '.instafeed__container .swiper-button-next',
//                 prevEl: '.instafeed__container .swiper-button-prev'
//             },
//             paginationClickable: true,
//             slidesPerView: 4,
//             slidesPerGroup: 1,
//             breakpoints: {},
//             spaceBetween: 10,
//         };
//         settings['breakpoints'][reponsive.xs] = {
//             slidesPerView: 2,
//             slidesPerGroup: 1
//         };
//         settings['breakpoints'][reponsive.sm] = {
//             slidesPerView: 4,
//             slidesPerGroup: 1
//         };
//         settings['breakpoints'][reponsive.md] = {
//             slidesPerView: 4,
//             slidesPerGroup: 1
//         };
//
//         var instafeedSlider = new Swiper('.instafeed__container', settings);
//
//     }
// }


var searchBox = document.getElementById('search_block_top');
var searchButtonMobile = $('.search_button_mobile');

const eventSearch = {
    'element': document.body,
    'type': 'click',
    'listener': function (event) {

        // Si on clique sur le bouton
        if (eventIsOnSelector(event, '.search_button_mobile')) {
            searchBox.classList.toggle('open');
            return true;
        }

        // si on clique sur le formulaire
        if (eventIsOnSelector(event, '#searchbox')) {
            return true;
        }

        // sinon on ferme
        searchBox.classList.remove('open');

    }
};


EventOn(eventSearch);


function prestablogHome() {
    const prestablogFeatureds = document.querySelectorAll('.prestablog__list .swiper');


    if (prestablogFeatureds !== null) {
        Array.prototype.forEach.call(prestablogFeatureds, function (prestablogFeatured) {

            const id = uniqId('presta-slider');
            prestablogFeatured.closest('.prestablog-slider-wrapper').setAttribute('id', id);


            new Swiper(prestablogFeatured, {
                modules: [Navigation, Pagination, Lazy],
                navigation: {
                    nextEl: '#' + id + ' .swiper-button-next',
                    prevEl: '#' + id + ' .swiper-button-prev',
                },
                slidesPerView: 1,
                spaceBetween: 30,
                // loop: true,
                //preloadImages: true,
                lazy: {
                    loadPrevNext: true,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }


            });

        });
    }

}

prestablogHome();


function scrollOnHash() {

    if(window.location.hash === '') {
        return;
    }

    let elem = null;
    if(window.location.hash.indexOf('/') === -1) {
        elem = document.querySelector(window.location.hash);
    }

    let offset = 100;
    if(window.location.hash === "#header-primary") {
        offset = 0;
    }

    if(elem !== null) {
        scrollIt(elem, offset, undefined, window);
    }

}

const eventHash = {
    'element': window,
    'type': 'hashchange',
    'listener': function (event) {
        scrollOnHash();
    }
};
EventOn(eventHash);

//scrollOnHash();

function uppercaseBrandLivreBleu(element) {
    if(element.textContent.indexOf('i') !== -1 && !element.children.length) {
        element.style.textTransform = 'none';
        element.textContent = element.textContent.toLocaleUpperCase('fr-FR').split('I').join('i');
    }
}

const listSelectorUppercase = [
    '.top-menu a',
    '.low-footer a',
    '.products .product-title__name',
    '.add-to-cart',
    '.elementor-button .elementor-button-text',
    '.product-line-head',
    '.cart-line-product-actions .remove-from-cart',
    '.btn',
];

const uppercaseElements = document.querySelectorAll(listSelectorUppercase.join(','));
Array.prototype.forEach.call(uppercaseElements, function (element) {
    uppercaseBrandLivreBleu(element);
})



